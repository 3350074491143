























import { Component, Vue, Watch } from 'vue-property-decorator'
import NotificationService from '@/services/NotificationService'
import { likeNotificationRef } from '@/firebase'
import { getLikeCount } from '@/helpers/GetNotificationCount'
import LikeNotificationCommon from '@/components/LikeNotification.vue'
@Component({
  components: {
    LikeNotificationCommon
  }
})
export default class LikeList extends Vue {
  private notifications: any[] = []
  private limit = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private page: number = 1
  private maxPage: number = Math.ceil(100 / this.limit)
  private isLoading: boolean = true
  private isLoadingLoadMore: boolean = false
  private unsubscribe: any

  async created() {
    this.getNotification()
    getLikeCount()

    // firebase get change unread notification
    this.handleRealtime()
  }

  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = likeNotificationRef
      .where('userIds', 'array-contains', userId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          this.getNotification()
          getLikeCount()
        }
      })
  }

  getNotification() {
    if (this.limit > 100) this.limit = 100

    NotificationService.getNotificationLike(this.limit, this.page)
      .then(res => {
        if (res.status === 200) {
          this.notifications = [...this.notifications, ...res.data.data]

          //set max is 100 items
          if (this.notifications.length > 100) {
            this.notifications = this.notifications.slice(0, 100)
          }

          //set max page
          this.maxPage =
            res.data.meta.pagination.total_pages < this.maxPage
              ? res.data.meta.pagination.total_pages
              : this.maxPage
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.isLoading = false
        this.isLoadingLoadMore = false
      })
  }

  loadMoreNotification() {
    if (this.page >= this.maxPage) return
    this.page++
    this.isLoadingLoadMore = true
    this.getNotification()
  }

  goToLike() {
    if (this.$route.name === 'like') {
      this.$emit('hideDropdownLike')
    } else {
      this.$router.push({ name: 'like' })
    }
  }
}
